// React
import { ReactElement } from 'react';
// Next
import { useTranslation } from 'next-i18next';
// Modules
import classNames from 'classnames/bind';
// Design System
import { Font } from '@utmb/design-system/text/font';
import { PiCategoryLogo } from '@utmb/design-system/pi-category-logo/PiCategoryLogo';
// Types
import { PiCategoryEnum } from '@utmb/types/enum/PiCategoryEnum';
// Design system
import { Slides } from '@utmb/design-system/slides/Slides';
// Styles
import styles from './world-index-top-filter.module.scss';

interface WorldIndexTopFilterProps {
    setCategory: (value: PiCategoryEnum) => void;
}

interface CategoryProps {
    isActive: boolean;
    category: PiCategoryEnum;
}

const cx = classNames.bind(styles);

const WorldIndexTopFilter = (props: WorldIndexTopFilterProps): ReactElement => {
    const { setCategory } = props;

    const { t } = useTranslation();

    const handleCategoryFilter = (category: PiCategoryEnum, index: number): void => {
        categories.forEach((cat, i) => {
            categories[i] = {
                ...cat,
                isActive: false,
            };
        });

        categories[index].isActive = true;
        setCategory(category);
    };

    const getButtonCSS = (isActive: boolean): any => cx(styles.witf_icon_button, { witf_icon_button_active: isActive });

    return (
        <div className={styles.witf_root}>
            <Font.OxaniumBold className={styles.witf_header}>{t('performanceIndex.filter')}</Font.OxaniumBold>
            <div className={styles.witf_categories_button_container}>
                <Slides
                    className={styles.witf_slider}
                    carouselOptions={{
                        containScroll: 'trimSnaps',
                        align: 'start',
                        draggable: true,
                        loop: false,
                    }}
                >
                    {() => (
                        <>
                            {categories.map(({ isActive, category }: CategoryProps, index: number) => (
                                <button key={index} className={getButtonCSS(isActive)} onClick={() => handleCategoryFilter(category, index)}>
                                    <PiCategoryLogo piCategory={category} height={30} isDark={true} />
                                </button>
                            ))}
                        </>
                    )}
                </Slides>
            </div>
        </div>
    );
};

export default WorldIndexTopFilter;

const categories = [
    { isActive: true, category: PiCategoryEnum.GENERAL },
    { isActive: false, category: PiCategoryEnum.PI20K },
    { isActive: false, category: PiCategoryEnum.PI50K },
    { isActive: false, category: PiCategoryEnum.PI100K },
    { isActive: false, category: PiCategoryEnum.PI100M },
];
