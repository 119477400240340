// React
import { ReactElement, memo } from 'react';
// Next
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
// Modules
import classNames from 'classnames/bind';
// Design System
import { Font } from '@utmb/design-system/text/font';
import { Link } from '@utmb/design-system/link/link';
import { Icon } from '@utmb/design-system/icons/Icon';
import { Placeholder } from '@utmb/design-system/placeholder/Placeholder';
import { Image } from '@utmb/design-system/image/Image';
// Types
import { IconsEnum } from '@utmb/types/icons';
import { PiCategoryEnum } from '@utmb/types/enum/PiCategoryEnum';
import TopRankedRunnerView from '@utmb/types/view/TopRankedRunnerView';
// Utils
import { v4 as uuidv4 } from 'uuid';
import { pIdToCloudinary } from '@utmb/utils/cloudinary';
// Styles
import styles from './world-index-top-runners.module.scss';

interface WorldIndexTopRunnersProps {
    category: PiCategoryEnum;
    runners: TopRankedRunnerView[];
    setBgMedia: (value: string) => void;
    setFocused?: (isFocused: boolean) => void;
}

const cx = classNames.bind(styles);

const WorldIndexTopRunners = memo((props: WorldIndexTopRunnersProps): ReactElement => {
    const { runners, category, setBgMedia, setFocused } = props;

    const { locale } = useRouter();
    const { t } = useTranslation();

    const getCategoryColor = (key: PiCategoryEnum): string => {
        switch (key) {
            case PiCategoryEnum.GENERAL:
                return styles.witr_color_general;
            case PiCategoryEnum.PI20K:
                return styles.witr_color_ws20k;
            case PiCategoryEnum.PI50K:
                return styles.witr_color_ws50k;
            case PiCategoryEnum.PI100K:
                return styles.witr_color_ws100k;
            case PiCategoryEnum.PI100M:
                return styles.witr_color_ws100m;
            default:
                return styles.witr_color_general;
        }
    };

    const witrRootCSS = cx(styles.witr_root, getCategoryColor(category));

    const toggleFocus = (element: HTMLLinkElement, add = true): void => {
        // Set current focused item a "focused" class
        element?.classList[add ? 'add' : 'remove'](styles.witr_hovered_card_focused);
        // Communicate to the parent the focus state
        if (setFocused) {
            setTimeout(() => setFocused(add), 100);
        }
    };

    return (
        <ul className={witrRootCSS}>
            {runners.map(({ rank, runner: { uri, ip, fullname, picture } }: TopRankedRunnerView) => (
                <li key={uuidv4()}>
                    <Link
                        href={`/${locale}/runner/${uri}`}
                        className={`${styles.witr_runner_card} ${styles.witr_hovered_card}`}
                        onFocus={(event) => toggleFocus(event.target)}
                        onBlur={(event) => toggleFocus(event.target, false)}
                        onMouseEnter={() => setBgMedia(picture)}
                        onMouseLeave={() => setBgMedia('')}
                    >
                        <div className={styles.witr_runner_card_wrapper}>
                            <div className={styles.witr_thumbnail_wrapper}>
                                {picture ? (
                                    <Image className={styles.image} objectFit="cover" layout="fill" image={pIdToCloudinary(picture, fullname)} />
                                ) : (
                                    <Placeholder isInvert={true} logoWidth={80} logoHeight={80} />
                                )}
                            </div>
                            <div className={styles.witr_content_container}>
                                <Font.FuturaHeavy mobile="50" desktop="80" className={styles.witr_position}>
                                    {rank}
                                </Font.FuturaHeavy>
                                <div>
                                    {ip && (
                                        <Font.OxaniumBold mobile="24" desktop="24" className={styles.witr_ip}>
                                            {ip}
                                        </Font.OxaniumBold>
                                    )}
                                    {fullname && (
                                        <Font.FuturaBold mobile="14" desktop="18" className={styles.witr_fullname}>
                                            {fullname}
                                        </Font.FuturaBold>
                                    )}
                                    <p className={styles.witr_link}>{t('performanceIndex.profileLink')}</p>
                                </div>
                            </div>
                            <div className={styles.witr_icon}>
                                <Icon variant={IconsEnum.CHEVRON_RIGHT} />
                            </div>
                        </div>
                    </Link>
                </li>
            ))}
        </ul>
    );
});

export default WorldIndexTopRunners;
