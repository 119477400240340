// React
import { ReactElement, useState } from 'react';
// Next
import { useTranslation } from 'next-i18next';
// Modules
import classNames from 'classnames/bind';
// Design System
import { Font } from '@utmb/design-system/text/font';
import { Image } from '@utmb/design-system/image/Image';
// Features
import WorldIndexTopFilter from './WorldIndexTopFilter';
import WorldIndexTopRunners from './WorldIndexTopRunners';
// Types
import { SliceEventsWorldIndexTopView } from '@utmb/types/index';
import { Slice } from '@utmb/types/slice-handler';
import { PiCategoryEnum } from '@utmb/types/enum/PiCategoryEnum';
import TopRunnersView from '@utmb/types/view/TopRunnersView';
// Utils
import { pIdToCloudinary } from '@utmb/utils/cloudinary';
// Styles
import styles from './world-index-top.module.scss';

const cx = classNames.bind(styles);

export const WorldIndexTop = (props: Slice<SliceEventsWorldIndexTopView>): ReactElement => {
    const { id, topMenRunners, topWomenRunners } = props;

    const [category, setCategory] = useState(PiCategoryEnum.GENERAL);
    const [showWomen, setShowWomen] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [bgMedia, setBgMedia] = useState('');

    const { t } = useTranslation();

    const witCardsContainerCSS = cx(styles.wit_cards_container, {
        wit_cards_container_focused: isFocused,
        wit_cards_container_toggled: showWomen,
    });

    const topRunners: TopRunnersView[][] = [topMenRunners, topWomenRunners];

    const runnersIndex = {
        [PiCategoryEnum.GENERAL]: 0,
        [PiCategoryEnum.PI20K]: 1,
        [PiCategoryEnum.PI50K]: 2,
        [PiCategoryEnum.PI100K]: 3,
        [PiCategoryEnum.PI100M]: 4,
    };

    return (
        <section id={id} className={styles.wit_root}>
            {bgMedia && <Image className={styles.wit_bg_img} layout="fill" objectFit="cover" image={pIdToCloudinary(bgMedia, t('performanceIndex.title'))} />}
            <div className="container">
                <Font.FuturaHeavy mobile="36" desktop="56" className={styles.wit_header}>
                    {t('performanceIndex.title')}
                </Font.FuturaHeavy>
                <WorldIndexTopFilter setCategory={setCategory} />
                <div className="row">
                    <button onClick={() => setShowWomen(false)} className={`col-6 ${styles.wit_gender_container} ${styles.wit_gender_men}`}>
                        <Font.FuturaHeavy mobile="26" desktop="36" className={styles.wit_gender}>
                            {t('common.gender.men')}
                        </Font.FuturaHeavy>
                    </button>
                    <button onClick={() => setShowWomen(true)} className={`col-6 ${styles.wit_gender_container} ${styles.wit_gender_women}`}>
                        <Font.FuturaHeavy mobile="26" desktop="36" className={styles.wit_gender}>
                            {t('common.gender.women')}
                        </Font.FuturaHeavy>
                    </button>
                </div>
            </div>
            <div className={`container ${styles.wit_top_runners_container}`}>
                <div className={witCardsContainerCSS}>
                    {topRunners.map((runners: TopRunnersView[], index: number) => (
                        <div className={styles.wit_card_wrapper} key={index}>
                            <WorldIndexTopRunners
                                runners={runners[runnersIndex[category] || 0].runners}
                                category={category}
                                setBgMedia={setBgMedia}
                                setFocused={setIsFocused}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};
